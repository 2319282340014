import { Input, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { FadeInOut } from '@kayne/ka-ui-lib-ng7';

@Component({
    selector: 'app-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.css'],
    animations: [FadeInOut]
})
export class SelectDropdownComponent implements OnInit {
    isDisabledItem = true;
    isCheckAll = false;
    constructor() {
        this.data = [];
    }
    /** value of the dropdown */
    @Input() data: any;
    @Input() isHideText = false;
    @Output() selectChange = new EventEmitter<any>();
    @Output() selectAllChange = new EventEmitter<any>();
    isShow = false;

    @ViewChild('list') element;

    @HostListener('document:click', ['$event']) clickedOutside($event) {
        // here you can hide your menu
        const el = $event.target.closest('.list-select');
        if (!el) {
            this.isShow = false;
        }
    }


    ngOnInit() {
    }

    togglePanel($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (!this.isShow) {
            this.checkIfSelectedAll();
        }
        this.isShow = !this.isShow;
    }

    handleOnChange() {
        this.checkIfSelectedAll();
        console.log(this.isCheckAll)
        this.selectChange.emit(this.data);
    }

    checkIfSelectedAll() {
        const selectedItems = this.data.filter(i => i.select === true || i.hide === false);
        console.log(selectedItems, this.data)
        this.isCheckAll = selectedItems.length === this.data.length;
        this.isDisabledItem = selectedItems.length === 1 || this.data.length === 1 ? true : false;
    }

    onSelectAll(e) {

        this.data.forEach(i => {
            // i.select = this.isCheckAll;
            i.hide = !this.isCheckAll;
        });
        this.checkIfSelectedAll();
        this.selectAllChange.emit(this.isCheckAll);
    }
}
