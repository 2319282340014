import { ModalMode, UtilityService, DateService, CommonService } from '@kayne/ka-ui-lib-ng7';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReadEndpointService } from 'src/app/services/read-endpoint.service';
import { FinalizedQuarter } from 'src/app/models/common.model';
import { finalize } from 'rxjs/operators';
// import * as $ from 'jquery';
// declare var $: any;

@Component({
    selector: 'app-setting-modal',
    templateUrl: './setting-modal.component.html',
    styleUrls: [`./setting-modal.component.css`]
})
export class SettingModalComponent implements OnInit {
    item: any;
    mode: ModalMode = ModalMode.ADD;
    isFormSubmitting = false;
    formErrors: string[] = [];
    quarters: any[] = [];
    modalClass: string = null;
    loading = false;
    currentSelectableQuarter = 6;
    currentFinalizedQuarter: FinalizedQuarter = { maxQuarterLoadedToDC: '' };

    get readonly() {
        return this.mode === ModalMode.VIEW;
    }

    constructor(
        private dateService: DateService,
        private utilityService: UtilityService,
        private readEndpointService: ReadEndpointService,
        private activeModal: NgbActiveModal,
        private commonService: CommonService
    ) {
        this.getSelectableQuarter(8);
    }

    ngOnInit() {
        // $(document).ready(() => {
        //     this.commonService.configureModal(this.modalClass);
        // });
        this.getFinalizedQuarter();
    }

    getSelectableQuarter(numOfQuarters: number) {
        this.quarters = [];
        for (let index = 1; index < numOfQuarters; index++) {
            const quarterDate = this.dateService.initDate().date(index).subtract(index, 'quarter').endOf('quarter').format('YYYY-MM-DD');
            this.quarters.push({
                id: quarterDate,
                name: `Q${this.dateService.initDate(quarterDate).quarter()}-${this.dateService.initDate(quarterDate).year()}`
            });
        }
    }

    private getFinalizedQuarter() {
        this.readEndpointService.getFinalizedQuarter().subscribe((response: string) => {
            if (response) {
                this.currentFinalizedQuarter.maxQuarterLoadedToDC = response;
            }
        }, error => {
            this.utilityService.toastWarning(`Fail to get current Setting data`);
        });
    }

    onSetFinalizedQuarter() {
        this.isFormSubmitting = true;
        this.readEndpointService.setFinalizedQuarter(this.currentFinalizedQuarter)
            .pipe(
                finalize(() => this.isFormSubmitting = false)
            )
            .subscribe(() => { }, response => {
                if (response) {
                    window.location.reload();
                    this.utilityService.toastSuccess(`Updated Finalized Quarter Successfully`);
                }
            });
    }

    onSelectQuarter() { }

    close() {
        this.activeModal.close();
    }

    hasFieldError(field: string) {
        return this.isFormSubmitting && this.formErrors.some(i => i === field);
    }
}
