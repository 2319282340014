import { GlobalFilter } from './../../models/common.model';
import { UtilityService } from '@kayne/ka-ui-lib-ng7';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DateService } from '@kayne/ka-ui-lib-ng7';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import * as dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as utc from 'dayjs/plugin/utc';
import { ReadEndpointService } from 'src/app/services/read-endpoint.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DEFAULT_FILTER_FUNDS, DEFAULT_FILTER_SECTORS, MainFilterService } from './services/main-filter.service';
import { MainFilterGeographyComponent } from './components/main-filter-geography/main-filter-geography.component';
import { GlobalFilterResponse } from './models/main-filter.model';
import { MainFilterCustomComponent } from './components/main-filter-custom/main-filter-custom.component';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

const ORG_CHART_DEFAULT_FUND = 'KAREP VI';
const DEFAULT_FILTER_VALUES: GlobalFilter = { funds: [], sectors: [], properties: [], geography: [], thirdPartyPropertyManagementCompanies: [] }
const GROUP_ROUTES_FINANCIALS = ['/covenants', '/financials', '/watchlist'];
const GROUP_ROUTES_VALUATIONS = ['/valuations'];
const GROUP_ROUTES_HOME_AND_RELATED = ['/home', '/opportunistic', '/core', '/impact', '/medical-office', '/seniors-housing', '/student-multi'];
const GROUP_ROUTES_ORG_CHART = ['/legal/org', '/legal/org-edit'];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-main-filter',
  templateUrl: './main-filter.component.html',
  styleUrls: [
    './main-filter.component.css'
  ]
})
export class MainFilterComponent implements OnInit, OnDestroy {

  isFilterReady = true;
  globalFilter: GlobalFilter = DEFAULT_FILTER_VALUES;
  private applyingGlobalFilter: { [url: string]: GlobalFilter };

  prevFundParent = ''; // for org chart
  prevFunds: string[] = []; // for other page
  prevSectors: string[] = [];
  prevProperties: string[] = [];

  allFunds = [];
  get funds() { return this.mainFilterService.funds; }
  get allProperty() { return this.mainFilterService.allProperty; }
  orgFunds = [];
  sectors = [];
  allSectors = [];
  allOrgChartSectors = [];
  orgChartSectors = [];
  geographies = [];
  selectedGeographies = [];
  selectedThirdParty = [];
  mockStatus = [];
  selectedStatus;

  mockCustom = [];
  selectedCustom;

  properties = [];
  allProperties = [];
  allOrgChartProperties = [];
  selectedProperty;

  capitalInvestments = [];
  allCapitalInvestments = [];
  selectedCapitalInvestment;

  quarters = [];
  allQuarter = [];
  currentRouter = '';
  selectedQuarter;

  thirdParties = [];

  allUsGeographies: any[];
  orgTypes = [];
  allThirdParties: any[];

  listFilter: string[];
  hasFilterChanged = false;
  isStartDateDisabled = false;
  isShowEndMonthOnly = false;
  enableSubtractStartDate = false;

  isDisabled = false;
  isHidden = false;
  previousUrl: string;
  isQuarterDisabled = false;
  defaultFilterFromQuery: GlobalFilter;

  @ViewChild('geographySelect') geographySelect: MainFilterGeographyComponent;
  @ViewChild('customSelect') customSelect: MainFilterCustomComponent;

  get isOrgChartPage() {
    return this.location.path() === '/legal/org' || this.location.path() === '/legal/org-edit';
  }

  get shouldDisableInput() {
    return !this.isFilterReady || this.isDisabled;
  }

  get hasGlobalFilterDiff() {
    return this.applyingGlobalFilter && this.applyingGlobalFilter[this.currentRouter] && !_.isEqual(this.applyingGlobalFilter[this.currentRouter], this.globalFilter);
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly utilityService: UtilityService,
    private readonly mainFilterService: MainFilterService,
    private readonly readEndpointService: ReadEndpointService,
    private readonly dateService: DateService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.onDetectRouterChange();
    this.readEndpointService.onGlobalFilterDataChangeReceived.subscribe(data => this.onGlobalFilterDataChangeReceived(data || {}));
    this.readEndpointService.onGlobalFilterOptionsChangeReceived.subscribe(data => data && this.onGlobalFilterOptionsChangeReceived(data));
    this.readEndpointService.onGlobalFilterBehaviorChanged.subscribe(data => this.onGlobalFilterBehaviorChangeReceived(data || {}));
  }

  ngOnInit() {
    const url = this.getRouteUrl();
    this.checkRouteQueryParams(url, () => {
      if (_.isEmpty(this.listFilter)) {
        this.initFilter(url);
      }
    });
  }

  private getRouteUrl() {
    const urls = this.router.url.split('?');
    return urls[0];
  }

  private checkRouteQueryParams(url: string, cb: () => void) {
    const email = this.route.snapshot.queryParams.email;
    if (_.isEmpty(email) || _.isNil(email) || url !== '/valuations') {
      cb();
      return;
    }
    this.readEndpointService.getDefaultFilterByEmail({ emailEncrypted: email }).subscribe(rs => {
      this.defaultFilterFromQuery = rs;
      cb();
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  private onDetectRouterChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initFilter(event.url);
      }
    });
  }

  private onGlobalFilterBehaviorChangeReceived({ isDisabled, isHidden }: { isDisabled?: boolean, isHidden?: boolean }) {
    this.isDisabled = isDisabled;
    this.isHidden = isHidden;
  }

  private onGlobalFilterOptionsChangeReceived(
    data: { properties: string[], funds: string[], isUpdatePropertyOptions?: boolean }
  ) {
    if (!data) return;

    const { properties, funds } = data;

    if (!_.isEmpty(funds)) {
      this.globalFilter.funds = funds;
    }

    if (!_.isEmpty(properties)) {
      this.globalFilter.properties = properties;
    }

    this.globalFilter.isReset = false;

    this.applyFilter();
  }

  private onGlobalFilterDataChangeReceived({
    isResetDefaultOptions, isUpdatePropertyOptions, isResetPropertyOptions,
    properties
  }: {
    isResetDefaultOptions?: boolean, isUpdatePropertyOptions?: boolean, isResetPropertyOptions?: boolean,
    properties?: any[]
  }) {
    if (isResetDefaultOptions) {
      if (!_.isNil(properties)) {
        this.globalFilter.properties = properties;
      }
    }

    if (isUpdatePropertyOptions) {
      this.onUpdatePropertiesChange();
    }

    if (isResetPropertyOptions && this.allProperties.length > 0) {
      this.properties = [...this.allProperties];
    }
  }

  onUpdatePropertiesChange() {
    this.properties = _.filter(this.allProperties, (property) => {
      const isSector = this.globalFilter.sectors && this.globalFilter.sectors.length === 0
        || _.includes(this.globalFilter.sectors, property.sector);
      const is3rdPartyProperty = this.globalFilter.thirdPartyPropertyManagementCompanies
        && this.globalFilter.thirdPartyPropertyManagementCompanies.length === 0
        || _.includes(this.globalFilter.thirdPartyPropertyManagementCompanies, property.thirdPartyPropertyManagementCompanies)
        || _.intersection(_.map(this.thirdParties, 'name'), property.thirdPartyPropertyManagementCompanies).length > 0;
      const isFund = this.globalFilter.funds && this.globalFilter.funds.length === 0
        || (this.globalFilter.funds.findIndex(fund => property.fund.toLowerCase().includes(fund.toLowerCase())) > -1);

      const isGeography = this.globalFilter.geography && this.globalFilter.geography.length === 0 || _.some(this.globalFilter.geography, (geo) => {
        return (!geo.data.geography || geo.data.geography === property.usGeography)
          && (!geo.data.division || geo.data.division === property.usDivision)
          && (!geo.data.state || geo.data.state === property.usState)
          && (!geo.data.metroArea || geo.data.metroArea === property.usMetroArea);
      });
      if (isFund && isSector && isGeography && is3rdPartyProperty) {
        return property;
      }
    });
    this.onUpdateOperatorChange();
  }

  onUpdateOperatorChange() {
    this.thirdParties = _.filter(this.allThirdParties, (thirdParty) => {
      const isSector = this.globalFilter.sectors && this.globalFilter.sectors.length === 0
        || (_.intersection(this.globalFilter.sectors, thirdParty.sector).length > 0);
      const isFund = this.globalFilter.funds && this.globalFilter.funds.length === 0
        || (_.intersection(this.globalFilter.funds, thirdParty.funds).length > 0);

      const isGeography = this.globalFilter.geography && this.globalFilter.geography.length === 0 || _.some(this.globalFilter.geography, (geo) => {
        return (!geo.data.geography || _.includes(thirdParty.usGeography, geo.data.geography))
          && (!geo.data.division || _.includes(thirdParty.usDivision, geo.data.division))
          && (!geo.data.state || _.includes(thirdParty.usState, geo.data.state))
          && (!geo.data.metroArea || _.includes(thirdParty.usMetroArea, geo.data.metroArea));
      });
      const isProperty = this.globalFilter.properties
        && this.globalFilter.properties.length === 0
        || (_.intersection(this.properties, thirdParty.property).length > 0) || (_.intersection(this.globalFilter.properties, thirdParty.property).length > 0);
      if (isFund && isSector && isGeography && isProperty) {
        return thirdParty;
      }
    });
  }

  getFilterQuarter(currentPage: string): Observable<string[] | null> {
    this.onUpdatePropertiesChange();
    switch (currentPage) {
      case '':
      case '/':
      case '/home':
      case '/user-assignment':
      case '/financials':
      case '/covenants':
        return this.readEndpointService.getHomeQuarters();
      case '/opportunistic':
      case '/core':
      case '/impact':
      case '/medical-office':
      case '/seniors-housing':
      case '/student-multi':
        this.resetFilter();
        return this.readEndpointService.getHomeQuarters();
      case '/legal/org':
      case '/legal/org-edit':
        return this.readEndpointService.getHomeQuarters();
      case '/valuations':
        return this.readEndpointService.getValuationQuarters();
      case '/covenant-watchlist':
      case '/watchlist':
      default:
        return of(null);
    }
  }

  setDefaultFilter(currentPage: string): void {
    this.resetFilterValues(currentPage); // decide if should reset filter values or not
    this.setDefaultValueForDateInput(currentPage); // set default filter value for Month Range inputs
    this.setDefaultValueForOtherInputs(currentPage); // set default filter value for other inputs

    // in case we get default filter values from email
    if (this.defaultFilterFromQuery) {
      this.setDefaultValueWhenQueryFilterExists();
    }
  }

  private bindQuarterToFilter(quarters: string[]): void {
    // default action
    this.allQuarter = this.convertFilterValue(quarters);
    this.quarters = this.allQuarter;
    this.mainFilterService.allQuarter = this.quarters;
    this.selectedQuarter = this.quarters.length > 0 ? this.quarters[0].id : null;
  }

  private setDefaultValueWhenQueryFilterExists() {
    const { funds, sectors, properties } = this.defaultFilterFromQuery;

    const prevFunds = [...(this.isOrgChartPage ? [] : this.prevFunds)];
    const prevSectors = [...(this.isOrgChartPage ? [] : this.prevSectors)];
    const prevProperties = [...(this.isOrgChartPage ? [] : this.prevProperties)];

    this.globalFilter.funds = [...this.globalFilter.funds, ...(funds || []), ...prevFunds];
    this.globalFilter.sectors = [...this.globalFilter.sectors, ...(sectors || []), ...prevSectors];
    this.globalFilter.properties = [...this.globalFilter.properties, ...(properties || []), ...prevProperties];
  }

  private resetFilterValues(currentPage: string) {
    let shouldReset = true;
    switch (currentPage) {
      case '/home':
      case '/opportunistic':
      case '/core':
      case '/impact':
      case '/medical-office':
      case '/seniors-housing':
      case '/student-multi':
        if (!GROUP_ROUTES_ORG_CHART.includes(this.previousUrl)) {
          shouldReset = false;
        }
        break;
      case '/valuations':
        if (!GROUP_ROUTES_ORG_CHART.includes(this.previousUrl)) {
          shouldReset = false;
        }
        break;
      case '/watchlist':
      case '/covenants':
      case '/financials':
        if (!GROUP_ROUTES_ORG_CHART.includes(this.previousUrl)) {
          shouldReset = false;
        }
        break;
      case '/legal/org':
      case '/legal/org-edit':
        if (this.previousUrl !== currentPage && GROUP_ROUTES_ORG_CHART.includes(this.previousUrl)) {
          shouldReset = false;
        }
        break;
      default:
        break;
    }

    if (shouldReset) {
      this.globalFilter = _.cloneDeep(DEFAULT_FILTER_VALUES);
      this.selectedGeographies = [];
    }
  }

  private setDefaultValueForOtherInputs(currentPage) {
    switch (currentPage) {
      case '/opportunistic':
        this.globalFilter.funds = DEFAULT_FILTER_FUNDS.OPPORTUNITY;
        break;
      case '/core':
        this.globalFilter.funds = [...DEFAULT_FILTER_FUNDS.CORE];
        break;
      case '/impact':
        this.globalFilter.funds = DEFAULT_FILTER_FUNDS.IMPACT;
        break;
      case '/medical-office':
        this.globalFilter.sectors = DEFAULT_FILTER_SECTORS.MEDICAL_OFFICE;
        break;
      case '/seniors-housing':
        this.globalFilter.sectors = [...DEFAULT_FILTER_SECTORS.SENIOR_HOUSING];
        break;
      case '/student-multi':
        this.globalFilter.sectors = DEFAULT_FILTER_SECTORS.STUDENT_MULTI;
        break;
      case '/legal/org':
      case '/legal/org-edit':
        if (_.isNil(this.globalFilter.fundParent) || _.isEmpty(this.globalFilter.fundParent)) {
          this.globalFilter.fundParent = ORG_CHART_DEFAULT_FUND;
          this.globalFilter.capitalInvestments = this.getDefaultCapitalInvestmentByFund(ORG_CHART_DEFAULT_FUND);
          this.globalFilter.sectors = [];
          this.globalFilter.properties = [];
          this.globalFilter.funds = [];
        }
        break;
      default:
        break;
    }
  }

  private setDefaultValueForDateInput(currentPage) {
    switch (currentPage) {
      case '':
      case '/':
      case '/opportunistic':
      case '/core':
      case '/impact':
      case '/medical-office':
      case '/seniors-housing':
      case '/student-multi':
      case '/user-assignment':
      case '/home':
        this.globalFilter.uiStartDate = undefined;
        this.globalFilter.uiEndDate = dayjs(this.selectedQuarter).toDate();
        this.globalFilter.quarterEndDate = this.selectedQuarter;
        break;
      case '/valuations':
        this.globalFilter.uiEndDate = dayjs(this.selectedQuarter).toDate();
        this.globalFilter.uiStartDate = dayjs(this.selectedQuarter).date(1).subtract(1, 'quarter').endOf('quarter').toDate();
        this.globalFilter.quarterEndDate = this.selectedQuarter;
        this.globalFilter.comparisonDate = dayjs(this.selectedQuarter).date(1).subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
        break;
      case '/watchlist':
      case '/covenants':
      case '/financials':
        if (this.previousUrl !== currentPage && !GROUP_ROUTES_FINANCIALS.includes(this.previousUrl)) {
          // reset start date & end date
          this.globalFilter.uiStartDate = this.globalFilter.startDate = undefined;
          this.globalFilter.uiEndDate = this.globalFilter.endDate = undefined;
        }

        this.globalFilter.quarterEndDate = this.selectedQuarter;
        break;
      default:
        break;
    }
  }

  resetFilter() {
    this.selectedQuarter = this.quarters.length > 0 ? this.quarters[0].id : null;

    Object.keys(this.globalFilter).forEach(key => {
      if (key === 'quarterEndDate') {
        this.globalFilter.quarterEndDate = this.selectedQuarter;
      } else {
        this.globalFilter[key] = [];
      }
    });

    if (this.geographySelect) {
      this.geographySelect.resetGeoFilterData();
    }

    if (this.isOrgChartPage) {
      this.globalFilter.fundParent = ORG_CHART_DEFAULT_FUND;
      this.globalFilter.capitalInvestments = this.getDefaultCapitalInvestmentByFund(ORG_CHART_DEFAULT_FUND);
    }

    this.selectedGeographies = [];
    this.selectedThirdParty = [];
    this.selectedCustom = null;
    this.selectedProperty = null;
    this.selectedStatus = null;
  }

  initFilter(url: string) {
    // Revert global filter input if not applied
    if (this.hasGlobalFilterDiff) {
      this.globalFilter = _.cloneDeep(this.applyingGlobalFilter[this.currentRouter]);
    }
    this.currentRouter = url;
    this.prepareFilterList();
    this.getReferenceData();

  }

  onResetFilter() {
    this.resetFilter();
    this.globalFilter.isReset = true;
    this.getReferenceData();
  }

  private convertFilterValue(input: string[]) {
    const allQuarters = [];
    input.forEach(f => {
      allQuarters.unshift({
        id: this.dateService.formatDate(f, 'YYYY-MM-DD'),
        name: `Q${dayjs(f).quarter()}-${dayjs(f).year()}`
      });
    });

    return allQuarters;
  }

  onSelectQuarter() {
    if (this.selectedQuarter) {
      this.globalFilter.quarterEndDate = this.selectedQuarter;
    }
  }

  onAddSector(e) {
    this.globalFilter.sectors = [...this.globalFilter.sectors, ...[e.id]];
  }

  onRemoveSector(e) {
    _.pull(this.globalFilter.sectors, e.value.id);
  }

  onAddFund(e) {
    this.globalFilter.funds = [...this.globalFilter.funds, ...[e.id]];
  }

  onRemoveFund(e) {
    _.pull(this.globalFilter.funds, e.value.id);
  }

  onSelectGlobalFilterChange(e, type = '') {
    if (e.target) {
      return;
    }

    if (this.isOrgChartPage) {
      this.updateOrgChartDefaultValues(e, type);
      this.updateOrgChartFilterOptions();
    } else {
      this.onUpdatePropertiesChange();
    }

    this.hasFilterChanged = true;
  }

  handlePropertyByFund() {
    // Remove properties if it is not belong to the selected funds
    if (!_.isEmpty(this.globalFilter.properties)) {
      this.properties.forEach(property => {
        const isFund = this.globalFilter.funds && this.globalFilter.funds.length === 0 || (this.globalFilter.funds.findIndex(fund => property.fund.toLowerCase() === (fund.toLowerCase())) > -1);
        if (!isFund) {
          this.globalFilter.properties = this.globalFilter.properties.filter(prop => property.property.toLocaleLowerCase() !== prop.toLocaleLowerCase());
        }
      })
    }
  }

  private prepareFilterList() {
    const url = this.location.path();
    this.isShowEndMonthOnly = false;
    this.enableSubtractStartDate = false;
    this.isStartDateDisabled = false;

    switch (url) {
      case '/legal/org':
      case '/legal/org-edit':
        this.listFilter = ['orgChartFund', 'orgChartSector', 'capitalInvestment', 'property', 'orgType'];
        break;
      case '/covenants':
      case '/financials':
        this.listFilter = ['fund', 'sector', 'geography', 'property', 'status', 'custom', 'monthRange'];
        break;
      case '/home':
      case '/opportunistic':
      case '/core':
      case '/impact':
      case '/medical-office':
      case '/seniors-housing':
      case '/student-multi':
        this.listFilter = ['fund', 'sector', 'geography', 'property', 'status', 'custom', 'monthRange'];
        this.isShowEndMonthOnly = true;
        this.isStartDateDisabled = true;
        break;
      default:
        this.listFilter = ['fund', 'sector', 'geography', 'property', 'status', 'custom', 'monthRange'];
        this.isShowEndMonthOnly = true;
        this.enableSubtractStartDate = true;
        break;
    }
  }

  private getDefaultCapitalInvestmentByFund(fund: string): string[] {
    const filteredCapitalInvestments = this.allCapitalInvestments.filter(i => i.funds.split(';').some(f => f === fund));
    if (_.isEmpty(filteredCapitalInvestments) || _.isNil(filteredCapitalInvestments)) return [];

    let defaultCapitalInvestmentId;
    switch (fund) {
      case 'KAMF':
        defaultCapitalInvestmentId = 'Rogers - AltaTerra';
        break;
      case 'KAHF':
        defaultCapitalInvestmentId = 'Littlestone';
        break;
      case 'KACORE':
        defaultCapitalInvestmentId = 'University of Florida Portfolio';
        break;
      case 'KAREP III':
        defaultCapitalInvestmentId = 'Conservatory Senior Living';
        break;
      case 'KAREP IV':
        defaultCapitalInvestmentId = 'Sentio Portfolio';
        break;
      case 'KAREP V':
        defaultCapitalInvestmentId = 'CDG Bloomington (IU)';
        break;
      case 'KAREP VI':
        defaultCapitalInvestmentId = 'Bush Temple';
        break;
      default:
        break;
    }

    const defaultCapitalInvestment = filteredCapitalInvestments.find(i => i.id === defaultCapitalInvestmentId);
    if (defaultCapitalInvestment) return [defaultCapitalInvestment.id];
    return [_.sortBy(filteredCapitalInvestments, ['id'])[0].id];
  }

  private prepareReferenceData(quarters, refData, orgRefData) {
    if (quarters) {
      this.bindQuarterToFilter(quarters);
    }

    if (this.isOrgChartPage) {
      this.prepareOrgChartReferenceData(orgRefData);
    } else {
      this.prepareCommonReferenceData(refData);
    }
  }

  private prepareCommonReferenceData(rs) {
    if (!rs) return;

    const { funds, sectors, properties, usGeographies, thirdPartyPropertyManagementCompanies } = rs;

    this.allUsGeographies = usGeographies;
    this.allThirdParties = [];
    this.mainFilterService.funds = [...funds.map(f => ({
      id: f,
      name: f,
      type: 'Select All',
    }))].sort((a, b) => (a.name < b.name ? -1 : 1));

    this.sectors = [...sectors.map(f => ({
      id: f,
      name: f,
      type: 'Select All',
    }))];

    this.properties = [...properties.map(f => ({
      ...f,
      id: f.property,
      name: f.property,
      type: 'Select All',
    }))].sort((a, b) => (a.name < b.name ? -1 : 1));
    this.mainFilterService.allProperty = _.cloneDeep(this.properties);
    this.thirdParties = thirdPartyPropertyManagementCompanies ? [...thirdPartyPropertyManagementCompanies.map(f => {
      const properties = _.filter(this.properties, { thirdPartyPropertyManagementCompanies: f });
      return ({
        id: f,
        name: f,
        property: _.uniq(_.map(properties, 'name')),
        sector: _.uniq(_.map(properties, 'sector')),
        usGeography: _.uniq(_.map(properties, 'usGeography')),
        usState: _.uniq(_.map(properties, 'usState')),
        usDivision: _.uniq(_.map(properties, 'usDivision')),
        usMetroArea: _.uniq(_.map(properties, 'usMetroArea')),
        funds: _.uniq(_.map(properties, 'fund')),
        type: 'Select All',
      })
    })] : [];
    this.thirdParties = _.sortBy(this.thirdParties, ['name']);
    this.allFunds = [...this.funds];
    this.allProperties = [...this.properties];
    this.allSectors = [...this.sectors];
    this.allThirdParties = [...this.thirdParties];
    this.readEndpointService.globalPropertyFilterData = this.allProperties;
  }

  private prepareOrgChartReferenceData(rs) {
    if (!rs) return;

    const { funds, properties, sectors, capitalInvestments } = rs as any;

    this.orgFunds = funds.map(e => ({
      id: e, name: e, type: 'SELECT ALL'
    }));

    this.allOrgChartProperties = properties.map(f => ({
      ...f,
      id: f.propertyName,
      name: f.propertyName,
      type: 'SELECT ALL',
    }));

    this.orgChartSectors = sectors.map(f => ({
      ...f,
      id: f.sector,
      name: f.sector,
      type: 'SELECT ALL',
    }));

    this.capitalInvestments = capitalInvestments.map(f => ({
      ...f,
      id: f.capitalInvestment,
      name: f.capitalInvestment,
      type: 'SELECT ALL',
    }));

    this.allCapitalInvestments = [...this.capitalInvestments];
    this.allOrgChartSectors = [...this.orgChartSectors];
  }

  getReferenceData() {
    // get quarter filter & reference data
    const referenceDataQueries: [Observable<string[]>, Observable<GlobalFilterResponse[]>, Observable<any[]>] = [
      this.getFilterQuarter(this.currentRouter),
      _.isEmpty(this.mainFilterService.funds) ? this.readEndpointService.getFilters() : of(null),
      this.isOrgChartPage ? this.readEndpointService.getOrgChartFilters() : of(null)
    ];

    this.isFilterReady = false;
    this.handleDisableQuarterFilter();
    forkJoin(referenceDataQueries).subscribe(([quarters, refData, orgRefData]) => {
      this.prepareReferenceData(quarters, refData, orgRefData);
      this.setDefaultFilter(this.currentRouter);

      if (this.isOrgChartPage) {
        // update org chart filter options based on default selections
        this.updateOrgChartFilterOptions();
      }

      this.previousUrl = this.currentRouter;
      this.isFilterReady = true;
      this.applyFilter();

    }, () => {
      this.utilityService.toastError('Fail to get filter reference data');
    });
  }

  private updateOrgChartDefaultValues(e, type: string) {
    switch (type) {
      case 'sector':
        break;
      case 'capitalInvestment':
        break;
      case 'fundParent':
        // in order to increase performance, 
        // set the default filter for capital investment to reduce payload size
        this.globalFilter.sectors = [];
        this.globalFilter.capitalInvestments = this.getDefaultCapitalInvestmentByFund(e.id);
        this.globalFilter.properties = [];
        break;
      default:
        break;
    }
  }

  updateOrgChartFilterOptions() {
    // update the org chart filter options based on user selections
    const allProperties = _.cloneDeep(this.allOrgChartProperties);
    const allSectors = _.cloneDeep(this.allOrgChartSectors);
    const allCapitalInvestments = _.cloneDeep(this.allCapitalInvestments);

    this.orgChartSectors = allSectors.filter(e => {
      return (!this.globalFilter.fundParent || e.funds.split(';').includes(this.globalFilter.fundParent));
    });
    // re-filter filter values with new set of master data
    this.globalFilter.sectors = [...this.globalFilter.sectors.filter(i => this.orgChartSectors.some(x => x.sector === i))];

    this.capitalInvestments = allCapitalInvestments.filter(e => {
      return (!this.globalFilter.fundParent || e.funds.split(';').includes(this.globalFilter.fundParent)) &&
        (!this.globalFilter.sectors || this.globalFilter.sectors.length === 0 || this.globalFilter.sectors.includes(e.sector));
    });
    // re-filter filter values with new set of master data
    this.globalFilter.capitalInvestments = [...this.globalFilter.capitalInvestments.filter(i => this.capitalInvestments.some(x => x.capitalInvestment === i))];

    this.properties = allProperties.filter(e => {
      return (!this.globalFilter.fundParent || e.funds.split(';').includes(this.globalFilter.fundParent)) &&
        (!this.globalFilter.capitalInvestments || this.globalFilter.capitalInvestments.length === 0 || this.globalFilter.capitalInvestments.includes(e.capitalInvestment)) &&
        (!this.globalFilter.sectors || this.globalFilter.sectors.length === 0 || this.globalFilter.sectors.includes(e.sector));
    });
    // re-filter filter values with new set of master data
    this.globalFilter.properties = [...this.globalFilter.properties.filter(i => this.properties.some(x => x.propertyName === i))];
  }

  onSelectMonthRange(event: { startDate?: Date, endDate?: Date, isStartDateChange?: boolean, isEndDateChange?: boolean }) {
    const { startDate, endDate, isStartDateChange, isEndDateChange } = event;

    if (isStartDateChange)
      this.globalFilter.uiStartDate = startDate;

    if (isEndDateChange)
      this.globalFilter.uiEndDate = endDate;

    if (isEndDateChange && endDate && this.enableSubtractStartDate) {
      const convertedEndDate = this.dateService.formatDateLocal(endDate, 'YYYY-MM-DD');
      this.globalFilter.uiStartDate = this.dateService.initDate(convertedEndDate).date(1).subtract(1, 'quarter').endOf('quarter').toDate();
    }

  }

  onGeographyAdd(newGeo: any) {
    this.selectedGeographies = [...newGeo, ...this.selectedGeographies];
    this.globalFilter.geography = [...newGeo, ...this.globalFilter.geography];
    this.onUpdatePropertiesChange();
  }

  onGeographyRemove(geo: any) {
    this.selectedGeographies = this.selectedGeographies.filter(item => item.id !== geo.id);
    this.globalFilter.geography = this.globalFilter.geography.filter(item => item.id !== geo.id);
    this.onUpdatePropertiesChange();
  }

  onInput(target: any, event: Event) {
    target.filter((event.target as HTMLInputElement).value);
  }

  ngOnDestroy() {
    this.readEndpointService.onGlobalFilterDataChangeReceived = new BehaviorSubject(null);
    this.readEndpointService.onGlobalFilterBehaviorChanged = new BehaviorSubject(null);
    this.readEndpointService.onGlobalFilterOptionsChangeReceived = new BehaviorSubject(null);
  }

  private handleDisableQuarterFilter(): void {
    this.isQuarterDisabled = this.currentRouter === '/financials' || this.currentRouter === '/covenants';
  }

  applyFilter() {
    const startDate = (this.globalFilter.uiStartDate && dayjs(this.globalFilter.uiStartDate).isValid()) ? this.dateService.formatDateLocal(this.dateService.initDate(this.globalFilter.uiStartDate).endOf('month'), 'YYYY-MM-DD') : null;
    const endDate = (this.globalFilter.uiEndDate && dayjs(this.globalFilter.uiEndDate).isValid()) ? this.dateService.formatDateLocal(this.dateService.initDate(this.globalFilter.uiEndDate).endOf('month'), 'YYYY-MM-DD') : null;

    this.globalFilter.startDate = startDate;
    this.globalFilter.endDate = endDate;

    // store to use later
    if (this.isOrgChartPage) {
      this.prevFundParent = this.globalFilter.fundParent;
    } else {
      this.prevFunds = this.globalFilter.funds;
      this.prevProperties = this.globalFilter.properties;
      this.prevSectors = this.globalFilter.sectors;
    }

    this.readEndpointService.onGlobalFilterChanged.next(this.globalFilter);
    this.applyingGlobalFilter = { [this.currentRouter]: _.cloneDeep(this.globalFilter) };
  }
}
