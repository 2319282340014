import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DateService } from '@kayne/ka-ui-lib-ng7';
import * as _ from 'lodash';

@Component({
    selector: 'app-month-range-picker',
    templateUrl: './month-range-picker.component.html',
    styleUrls: [`./month-range-picker.component.css`]
})
export class MonthRangePickerComponent {
    loading = false;
    currentOpenedCalendar;

    @Input() isStartDateDisabled = false;
    @Input() isEndDateDisabled = false;
    @Input() onlyAllowEndQuarterMonth = false;
    @Input() enableSubtractStartDate = false;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Output() change: EventEmitter<{ startDate: Date, endDate: Date, isStartDateChange?: boolean, isEndDateChange?: boolean }> = new EventEmitter();

    @ViewChild('startDatePicker') startDatePicker: Element;
    @ViewChild('endDatePicker') endDatePicker: Element;

    constructor(private readonly dateService: DateService) { }

    onStartDateChange(event) {
        this.change.emit({ startDate: this.convertToEndMonthDate(event.value), endDate: this.endDate, isStartDateChange: true });
    }

    onEndDateChange(event) {
        this.change.emit({ startDate: this.startDate, endDate: this.convertToEndMonthDate(event.value), isEndDateChange: true });
    }

    private convertToEndMonthDate(dateValue?: any) {
        if (!dateValue) return undefined;
        return this.dateService.initDate(dateValue).endOf('month').startOf('date').toDate();
    }

    onOpen(args: any) {
        if (args && this.onlyAllowEndQuarterMonth) {
            this.currentOpenedCalendar = args.popup.element as HTMLElement;
            this.customPopupMonthCell(this.currentOpenedCalendar);
        }
    }

    onNavigate(args: any) {
        if (this.currentOpenedCalendar && this.onlyAllowEndQuarterMonth) {
            this.customPopupMonthCell(this.currentOpenedCalendar);
        }
    }

    customPopupMonthCell(currentOpenPopup: HTMLElement) {
        let dataCells = currentOpenPopup.getElementsByClassName('e-day');
        for (let index = 0; index < dataCells.length; index++) {
            const monthData = (dataCells[index] as HTMLElement).innerText;
            if (monthData && _.isNaN(+monthData) && monthData !== 'Mar'
                && monthData !== 'Jun' && monthData !== 'Sep' && monthData !== 'Dec') {
                (dataCells[index] as HTMLElement).parentElement.classList.add('disabled-month');
            }
        }
    }
}
