import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChild, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { ReadEndpointService } from 'src/app/services/read-endpoint.service';
import { GlobalGeographyFilter } from 'src/app/models/common.model';

@Component({
    selector: 'app-main-filter-geography',
    templateUrl: './main-filter-geography.component.html',
    styleUrls: [
        './main-filter-geography.component.css'
    ]
})
export class MainFilterGeographyComponent implements OnInit, OnChanges, OnDestroy {
    selectedFullGeo: GlobalGeographyFilter = { geography: null, division: null, state: null, metroArea: null };
    selectedDivision;
    selectedState;
    selectedMetroArea;

    cachedDivisions = [];
    cachedStates = [];
    cachedMetroArea = [];
    cachedGeographies = [];

    isGeoOpened = false;

    geographies = [];
    divisions = [];
    states = [];
    metroArea = [];

    @Input() source: any;
    @Input() selectedGeographies = [];

    @Output() add = new EventEmitter<any>();
    @Output() remove = new EventEmitter<any>();

    @ViewChild('geographyDropdown') geographyDropdown: ElementRef;

    get isGeoValid() {
        return !this.selectedFullGeo.geography && !this.selectedFullGeo.state && !this.selectedFullGeo.division && !this.selectedFullGeo.metroArea;
    }

    constructor(private renderer: Renderer2) {
        this.onRendererChange();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.source) {
            this.prepareData(this.source);
        }

        if (changes.selectedGeographies && this.selectedGeographies.length === 0) {
            this.selectedFullGeo = { geography: null, division: null, state: null, metroArea: null };
        }
    }

    ngOnInit() {
    }

    prepareData(source: any = []) {
        // geo
        this.divisions = [];
        this.geographies = source
            .map((usGeography) => {
                const usDivisions = usGeography.usDivisions
                    .map(el => ({
                        id: el.name,
                        name: el.name,
                        parent: usGeography.name,
                        type: 'Select All',
                        usStates: el.usStates.sort((a, b) => (a.name < b.name ? -1 : 1)),
                    }));

                usDivisions.forEach(division => {
                    this.divisions.push(division);
                });

                return {
                    id: usGeography.name,
                    name: usGeography.name,
                    type: 'Select All',
                    usDivisions: usDivisions.sort((a, b) => (a.name < b.name ? -1 : 1))
                };
            });

        // states
        this.states = [];
        this.divisions
            .filter((division) => division.usStates)
            .forEach((division) => {
                division.usStates = division.usStates
                    .map(el => ({
                        id: el.name,
                        name: el.name,
                        parent: division.name,
                        type: 'Select All',
                        usMetroAreas: el.usMetroAreas.sort((a, b) => (a < b ? -1 : 1))
                    }));

                division.usStates.forEach(state => {
                    this.states.push(state);
                });
            });

        // metro area
        this.metroArea = [];
        this.states
            .filter((state) => state.usMetroAreas)
            .forEach((state) => {
                state.usMetroAreas = state.usMetroAreas
                    .map((metroArea) => ({
                        id: metroArea,
                        name: metroArea,
                        parent: state.name,
                        type: 'Select All'
                    }));

                state.usMetroAreas.forEach((metroArea) => {
                    this.metroArea.push(metroArea);
                });
            });

        // cache
        this.cachedStates = [...this.states.sort((a, b) => (a.name < b.name ? -1 : 1))];
        this.cachedMetroArea = [...this.metroArea.sort((a, b) => (a.name < b.name ? -1 : 1))];
        this.cachedDivisions = [...this.divisions.sort((a, b) => (a.name < b.name ? -1 : 1))];
        this.cachedGeographies = [...this.geographies.sort((a, b) => (a.name < b.name ? -1 : 1))];
    }

    private onRendererChange() {
        this.renderer.listen('window', 'click', (e: any) => {
            const className = e.target && e.target.className && typeof e.target.className === 'string' ? e.target.className : '';
            if (!this.geographyDropdown.nativeElement.contains(e.target)
                && (e.target.className && className !== 'ng-star-inserted' && className.includes('ng-value-icon') === false
                    && className.includes('ng-option') === false)) {
                this.isGeoOpened = false;
            }
        });
    }

    parentCheck(currentData, type: string, fullSearchGeo) {
        let isConnectedGeo = false;
        switch (type) {
            case 'division':
                isConnectedGeo = fullSearchGeo && fullSearchGeo.geography && fullSearchGeo.geography.id === currentData.parent;
                break;
            case 'state':
                isConnectedGeo = fullSearchGeo && fullSearchGeo.division && fullSearchGeo.division.id === currentData.parent;
                if (!isConnectedGeo && fullSearchGeo && fullSearchGeo.geography) {
                    isConnectedGeo = fullSearchGeo.geography.usDivisions.find(d => d.id === currentData.parent) ? true : false;
                }
                break;
            case 'metroArea':
                isConnectedGeo = fullSearchGeo && fullSearchGeo.state && fullSearchGeo.state.id === currentData.parent;
                if (fullSearchGeo && fullSearchGeo.division && !isConnectedGeo) {
                    isConnectedGeo = fullSearchGeo.division.usStates.find(st => st.id === currentData.parent) ? true : false;
                    if (fullSearchGeo && fullSearchGeo.geography && !isConnectedGeo) {
                        isConnectedGeo = fullSearchGeo.geography.usDivisions.find(dv => dv.usStates.find(st => st.id === currentData.parent)) ? true : false;
                    }
                }
                break;
            default:
                break;
        }
        return isConnectedGeo;
    }

    createSubmitData(geoData = [], type: string, submitData = []) {
        if (geoData && geoData.length > 0) {
            geoData.forEach((data) => {
                const submitDataConverted = submitData;
                if (submitDataConverted.length > 0) {
                    submitData.map((sd, index) => {
                        if (this.parentCheck(data, type, sd)) {
                            submitDataConverted[index][type] = data;
                        }
                        const filterData = submitDataConverted.filter(i => Object.keys(i).find(key => key === type && i[key].id === data.id));
                        if (filterData.length === 0) {
                            submitDataConverted.push({ [type]: data });
                        }
                    });
                } else {
                    submitDataConverted.push({ [type]: data });
                }
                submitData = submitDataConverted;
            });
        }
    }

    convertSubmitGeo() {
        const convertedData: any = [];
        let submitData: any = [];
        this.createSubmitData(this.selectedFullGeo.geography, 'geography', convertedData);
        this.createSubmitData(this.selectedFullGeo.division, 'division', convertedData);
        this.createSubmitData(this.selectedFullGeo.state, 'state', convertedData);
        this.createSubmitData(this.selectedFullGeo.metroArea, 'metroArea', convertedData);
        const removedIndex = [];
        convertedData.map((data, index) => {
            Object.keys(data).forEach(key => {
                if (Object.keys(data).length === 1 && convertedData.filter(cvtData => Object.keys(cvtData).length > 1
                    && cvtData[key] && cvtData[key].id === data[key].id).length > 0) {
                    removedIndex.push(index);
                }
            });
        });

        submitData = convertedData.map((data) => {
            Object.keys(data).forEach(key => {
                data[key] = data[key].id;
            });

            return {
                data,
                id: Object.values(data).join(', ')
            };
        });
        removedIndex.forEach(indexItem => submitData.splice(indexItem, 1));
        return submitData;
    }

    toggleGeo() {
        this.isGeoOpened = !this.isGeoOpened;
    }

    removeGeo(event, data) {
        event.stopPropagation();
        this.remove.emit({ ...data });
    }

    onClearGeoDropdown() { }

    onSelectGeoDropdown() { }

    addGeo() {
        const convertedSubmitGeo = this.convertSubmitGeo();

        this.resetGeoFilterData();
        this.isGeoOpened = false;

        this.add.emit([...convertedSubmitGeo]);
    }

    resetGeoFilterData() {
        this.geographies = this.cachedGeographies;
        this.divisions = this.cachedDivisions;
        this.states = this.cachedStates;
        this.metroArea = this.cachedMetroArea;
        // this.selectedFullGeo = { geography: null, division: null, state: null, metroArea: null };
    }

    onInput(target: any, event: Event) {
        target.filter((event.target as HTMLInputElement).value);
    }

    ngOnDestroy() { }

}
