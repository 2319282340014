import { NgModule, ModuleWithProviders } from '@angular/core';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomPercentPipe } from './pipes/custom-percent.pipe';
import { CustomDecimalPipe } from './pipes/custom-decimal.pipe';
import { CurrencySuffixesPipe } from './pipes/custom-currency-suffix.pipe';
import { NumberDirective } from './directives/number-only.directive';

@NgModule({
    declarations: [
        CustomCurrencyPipe,
        CustomPercentPipe,
        CustomDecimalPipe,
        CurrencySuffixesPipe,
        NumberDirective,
    ],
    exports: [
        CustomCurrencyPipe,
        CustomPercentPipe,
        CustomDecimalPipe,
        CurrencySuffixesPipe,
    ],
    imports: []
})
export class KarSharingModule {
    static forRoot(): ModuleWithProviders<KarSharingModule> {
        return {
            ngModule: KarSharingModule,
            providers: [
                CustomCurrencyPipe,
                CustomPercentPipe,
                CustomDecimalPipe,
                CurrencySuffixesPipe,
            ]
        };
    }
}
